// src/components/PropertyCard.js
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import CardFooters from "./CardFooters";
import axios from "axios"; // Import axios for API calls

import "../assets/css/property-card.css";

const PropertyCard = () => {
  const [property, setProperty] = useState([]);

  // Fetch data from backend
  useEffect(() => {
    // Fetch properties from the API
    axios
      .get("http://localhost:5000/api/properties")
      .then((response) => {
        setProperty(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the properties!", error);
      });
  }, []);
  return (
    <>
      {property.map((property) => (
        <div key={property.PM_ID} className="property-card">
          <div>
            <div className="property-card-img" style={{ width: "100%" }}>
              <img
                // src={property.PM_Images}
                src={property.PM_Images}
                alt={property.PM_Title}
                className="pc"
              />
              <h3>{property.PM_Title}</h3>
              <p>Address :{property.PM_Location} </p>
              <p>Price : Rs {property.PM_Price} </p>
              <div className="property-feature">
                <p className="half-width"> Bedrooms {property.PM_Bedrooms}</p>
                <span className="half-width">
                  {" "}
                  Bathrooms {property.PM_Bathrooms}
                </span>
                <p className="half-width">Area: {property.PM_Area}sq ft</p>
                <span className="half-width">Type:{property.PM_Type} </span>
                <p className="half-width">Status: {property.PM_IsActive}</p>
                <span className="half-width">
                  Listed on: {property.PM_CreatedAt}
                </span>
              </div>

              {/* <Link to={`/propertypage/${property.id}`}>View Details</Link */}
              <CardFooters></CardFooters>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PropertyCard;
