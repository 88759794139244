// src/components/SearchBar.js
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import "../assets/css/SearchBar.css";
const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleSearch = () => {
    onSearch(query);
  };

  return (
    <div className="search-bar-container">
      {/* <button className="search-icon" onClick={toggleSearchBar}>
        {isSearchOpen ? <FaTimes /> : <FaSearch />}
      </button>

      {isSearchOpen && ( */}
      <div>
        <div className="search-bar">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search properties..."
          />
          <button className="btn-search" onClick={handleSearch}>
            <FaSearch />
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default SearchBar;
