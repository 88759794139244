import React, { useState } from "react";
import "../assets/css/ImageGallery.css"; // Optional: Import a CSS file for styling
function ImageGallery({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Check if images array is provided and has at least one image
  if (!images || images.length === 0) {
    return <div>No images available</div>;
  }

  const handlePreviousClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="image-gallery">
      <img
        src={images[currentImageIndex]}
        alt={`Property Image ${currentImageIndex + 1}`}
        className="gallery-image"
      />
      <div className="gallery-controls">
        <button onClick={handlePreviousClick} className="gallery-button">
          Previous
        </button>
        <button onClick={handleNextClick} className="gallery-button">
          Next
        </button>
      </div>
    </div>
  );
}

export default ImageGallery;
