import { React, useState } from "react";
import "../assets/css/Header2.css";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

const Header2 = () => {
  const [cartItems, setCartItems] = useState([]);
  const handleAddToCart = (property) => {
    setCartItems([...cartItems, property]);
  };

  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  return (
    <div className="Header2">
      <div class="topnav" id="myTopnav">
        <a href="/" className="logo">
          {" "}
          HOME
          {/* <img
            src="https://loremflickr.com/100/40/logo"
            alt="logo"
            className="Logo"
          /> */}
        </a>
        {/* <Link to="/" class="active">
          Home
        </Link> */}
        {/* <Link to="/gallery">Gallery</Link>
        <Link to="/contact">Contact</Link> */}

        <div class="dropdown">
          <button class="dropbtn">
            Post Properties
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="/addproperty">Add property</a>
            <a href="/addcountry">Add Country</a>
            <a href="/addstate">Add State</a>
            <a href="/addcity">Add City</a>
            {/* <a href="/addproperty">Post Residential Rent</a>
            <a href="/">Post Residential Sale</a>
            <a href="/">Post Commercial Rent</a>
            <a href="/">Post Commercial Sale</a> */}
          </div>
        </div>

        <div class="dropdown">
          <button class="dropbtn">
            Post Your Requirement
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="/">I Am A Buyer </a>
            <a href="/">I Am A Tenant </a>
            <a href="/">I Am A Agent </a>
          </div>
        </div>
        <Link to="/">Buy Our Services</Link>
        <div class="dropdown">
          <button class="dropbtn">
            Property Listing
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="/">Weekly</a>
            <a href="/">Monthly</a>
          </div>
        </div>
        <div>
          <SearchBar></SearchBar>
        </div>
        {/* <div class="dropdown">
          <button class="dropbtn">
            Dropdown
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="/">Link 1</a>
            <a href="/">Link 2</a>
            <a href="/">Link 3</a>
          </div>
        </div>
        <a href="#about">About</a>
        <a href="/cart">Cart: {cartItems.length} items </a>
        <a href="javascript:void(0);" className="icon" onClick={myFunction}>
          &#9776;
        </a> */}
      </div>
    </div>
  );
};

export default Header2;
