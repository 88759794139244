import React, { useState } from "react";
import Filters from "../components/Filter";
import PropertyCard from "../components/PropertyCard";

const ListingsPage = () => {
  const [filteredProperties, setFilteredProperties] = useState([]);

  const properties = [
    {
      id: 1,
      title: "Luxury Villa",
      description: "5 beds, 4 baths ",
      image: "https://loremflickr.com/150/150/house",
    },
    {
      id: 2,
      title: "Cozy Cottage",
      description: "3 beds, 2 baths",
      image: "https://loremflickr.com/151/150/house",
    },
  ];

  const handleFilterChange = (filters) => {
    const filtered = properties.filter((property) => {
      return (
        (filters.location === "" ||
          property.location.includes(filters.location)) &&
        (filters.minPrice === "" ||
          property.price >= parseInt(filters.minPrice)) &&
        (filters.maxPrice === "" ||
          property.price <= parseInt(filters.maxPrice))
      );
    });
    setFilteredProperties(filtered);
  };

  return (
    <main>
      {/* <Filters onFilterChange={handleFilterChange} />
      <section className="property-list">
        <h2>Available Properties</h2>
        {filteredProperties.map((property) => (
          <PropertyCard key={property.id} property={property} />
        ))}
      </section> */}
    </main>
  );
};

export default ListingsPage;
