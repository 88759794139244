import React, { useState, useEffect } from "react";
import axios from "axios";

const StateMaster = () => {
  const [stateName, setStateName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch countries on page load
  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/getcountries"
        );
        setCountries(response.data);
      } catch (err) {
        console.error("Error fetching countries:", err);
        setError("Failed to fetch countries");
      }
    }
    fetchCountries();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/api/addstates", {
        SM_StateName: stateName,
        SM_CountryId: countryId,
        IsActive: isActive,
      });
      setMessage(response.data.message);
      setStateName("");
      setCountryId("");
    } catch (err) {
      console.error("Error adding state:", err);
      setError("Failed to add state. Please try again.");
    }
  };

  return (
    <div>
      <h2>Add State</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={stateName}
          onChange={(e) => setStateName(e.target.value)}
          placeholder="State Name"
          required
        />
        <select
          value={countryId}
          onChange={(e) => setCountryId(e.target.value)}
          required
        >
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country.CM_CountryID} value={country.CM_CountryID}>
              {country.CM_CountryName}
            </option>
          ))}
        </select>
        <label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          Is Active
        </label>
        <button type="submit">Add State</button>
      </form>
      {message && <p>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default StateMaster;
