import "./App.css";

// my code start
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header2 from "./components/Header2";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ListingsPage from "./pages/ListingsPage";
import PropertyDetailsPage from "./pages/PropertyDetailsPage";
import NotFoundPage from "./pages/NotFoundPage";
import ErrorBoundary from "./components/ErrorBoundary";
import ImageGallery from "./components/ImageGallery";
import AddProductsPage from "./pages/AddPropertyPage";
import Login from "./components/Login";
import Register from "./components/Register";
import Cart from "./components/Cart";
import SingleProperty from "./components/SingleProperty";
import CountryMaster from "./pages/Admin/CountryMaster";
import StateMaster from "./pages/Admin/StateMaster";
import CityMaster from "./pages/Admin/CityMaster";
import TehsileMaster from "./pages/Admin/TehsilMaster";
import VillageMaster from "./pages/Admin/VillageMaster";

function App() {
  return (
    <div className="App">
      <Router>
        <Header2 />
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/listings" element={<ListingsPage />} />
            <Route path="/propertypage/:id" element={<SingleProperty />} />
            <Route path="/property/:id" element={<PropertyDetailsPage />} />
            <Route path="/gallery" element={<ImageGallery />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/addproperty" element={<AddProductsPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/addcountry" element={<CountryMaster />} />
            <Route path="/addstate" element={<StateMaster />} />
            <Route path="/addcity" element={<CityMaster />} />
            <Route path="/addtehsil" element={<TehsileMaster />} />
            <Route path="/addvillage" element={<VillageMaster />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ErrorBoundary>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
