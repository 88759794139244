import React from "react";
import ContactForm from "../components/ContactForm";
import "../assets/css/ContactPage.css";

// Optional: Import a CSS file for styling

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>
        If you have any questions or inquiries, feel free to reach out to us
        using the form below.
      </p>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
