import React, { useState, useEffect } from "react";
import axios from "axios";

const CityMaster = () => {
  const [cityName, setCityName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch countries on page load
  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/getcountries"
        );
        setCountries(response.data);
      } catch (err) {
        console.error("Error fetching countries:", err);
        setError("Failed to fetch countries");
      }
    }
    fetchCountries();
  }, []);

  // Fetch states based on selected country
  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/getstates/${countryId}`
      );
      setStates(response.data);
    } catch (err) {
      console.error("Error fetching states:", err);
      setError("Failed to fetch states");
    }
  };

  // Handle country change
  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    setCountryId(selectedCountryId);
    fetchStates(selectedCountryId); // Fetch states based on the selected country
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/api/addcity", {
        CM_CityName: cityName,
        CM_StateId: stateId,
        IsActive: isActive,
      });
      setMessage(response.data.message);
      setCityName("");
      setCountryId("");
      setStateId("");
      setStates([]); // Clear states after submission
    } catch (err) {
      console.error("Error adding city:", err);
      setError("Failed to add city. Please try again.");
    }
  };

  return (
    <div>
      <h2>Add City</h2>
      <form onSubmit={handleSubmit}>
        {/* City Name */}
        <input
          type="text"
          value={cityName}
          onChange={(e) => setCityName(e.target.value)}
          placeholder="City Name"
          required
        />

        {/* Country Dropdown */}
        <select value={countryId} onChange={handleCountryChange} required>
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country.CM_CountryID} value={country.CM_CountryID}>
              {country.CM_CountryName}
            </option>
          ))}
        </select>

        {/* State Dropdown */}
        <select
          value={stateId}
          onChange={(e) => setStateId(e.target.value)}
          required
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.SM_StateID} value={state.SM_StateID}>
              {state.SM_StateName}
            </option>
          ))}
        </select>

        {/* Is Active */}
        <label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          Is Active
        </label>

        <button type="submit">Add City</button>
      </form>

      {message && <p>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default CityMaster;
