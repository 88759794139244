import React from "react";
import "../assets/css/HomeSection.css";
import { useState } from "react";

const HomeSection = () => {
  const [activeTab, setActiveTab] = useState("buy");

  const renderContent = () => {
    switch (activeTab) {
      case "buy":
        return (
          <div className="search-bar-tab">
            <select className="select-city">
              <option>Select City</option>
              {/* Add more options as needed */}
            </select>
            <select className="select-suboption">
              <option>-- Select City First --</option>
              {/* Sub-options based on city */}
            </select>
            <select className="select-floor">
              <option>Select Floor</option>
              {/* Add more options as needed */}
            </select>
            <select className="select-room">
              <option>Select Room</option>
              {/* Add more options as needed */}
            </select>
            <div className="search-input-group">
              <input
                type="number"
                placeholder="Min Price"
                className="min-price"
              />
              <input
                type="number"
                placeholder="Max Price"
                className="max-price"
              />
            </div>
            <div className="search-input-group">
              <input
                type="number"
                placeholder="Min Area"
                className="min-area"
              />
              <input
                type="number"
                placeholder="Max Area"
                className="max-area"
              />
            </div>
            <button className="search-btn">Search</button>
          </div>
        );
      case "rent":
        return (
          <div className="search-bar-tab">
            <select className="select-city">
              <option>Select City</option>
              {/* Add more options as needed */}
            </select>
            <select className="select-suboption">
              <option>-- Select City First --</option>
              {/* Sub-options based on city */}
            </select>
            <select className="select-floor">
              <option>Select Floor</option>
              {/* Add more options as needed */}
            </select>
            <select className="select-room">
              <option>Select Room</option>
              {/* Add more options as needed */}
            </select>
            <div className="search-input-group">
              <input
                type="number"
                placeholder="Min Rent"
                className="min-price"
              />
              <input
                type="number"
                placeholder="Max Rent"
                className="max-price"
              />
            </div>
            <div className="search-input-group">
              <input
                type="number"
                placeholder="Min Area"
                className="min-area"
              />
              <input
                type="number"
                placeholder="Max Area"
                className="max-area"
              />
            </div>
            <button className="search-btn">Search</button>
          </div>
        );
      case "projects":
        return (
          <div className="search-bar-tab">
            <select className="select-city">
              <option>Select City</option>
              {/* Add more options as needed */}
            </select>
            <select className="select-project">
              <option>Select Project</option>
              {/* Add more options as needed */}
            </select>
            <button className="search-btn">Search Projects</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="homepage">
      <div className="header">
        <h1>Find Your Dream Apartment</h1>
        <div className="action-buttons">
          <button
            className={`btn ${activeTab === "buy" ? "active" : ""}`}
            onClick={() => setActiveTab("buy")}
          >
            Buy
          </button>
          <button
            className={`btn ${activeTab === "rent" ? "active" : ""}`}
            onClick={() => setActiveTab("rent")}
          >
            Rent
          </button>
          <button
            className={`btn ${activeTab === "projects" ? "active" : ""}`}
            onClick={() => setActiveTab("projects")}
          >
            Projects
          </button>
        </div>
      </div>
      <div className="search-section">{renderContent()}</div>
    </div>
  );
};
export default HomeSection;
