import React, { useState } from "react";
import axios from "axios";

const CountryMaster = () => {
  const [countryName, setCountryName] = useState(""); // State to store country name
  const [message, setMessage] = useState(""); // State to display response message
  const [error, setError] = useState(""); // State to handle error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!countryName) {
      setError("Country name is required");
      return;
    }

    try {
      // Post request to add the country
      const response = await axios.post(
        "http://localhost:5000/api/addcountries",
        {
          CM_CountryName: countryName,
        }
      );

      // Handle success response
      setMessage(response.data.message);
      setError(""); // Clear any previous error
      setCountryName(""); // Clear input field after successful addition
    } catch (err) {
      // Handle error
      console.error("Error adding country: ", err);
      setError("Failed to add country. Please try again.");
    }
  };

  return (
    <div>
      <h2>Add Country</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Country Name:</label>
          <input
            type="text"
            value={countryName}
            onChange={(e) => setCountryName(e.target.value)}
            placeholder="Enter country name"
            required
          />
        </div>
        <button type="submit">Add Country</button>
      </form>

      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default CountryMaster;
