import React from "react";
import AuthFormLayout from "./AuthFormLauout";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <AuthFormLayout>
      <h2>Register</h2>
      <form>
        <div className="auth-form-group">
          <label>Name</label>
          <input type="text" placeholder="Enter your name" required />
        </div>
        <div className="auth-form-group">
          <label>Email</label>
          <input type="email" placeholder="Enter your email" required />
        </div>
        <div className="auth-form-group">
          <label>Password</label>
          <input type="password" placeholder="Enter your password" required />
        </div>
        <button type="submit">Register</button>
      </form>
      <p>
        Already have an account? <Link to="/login">Login here</Link>
      </p>
    </AuthFormLayout>
  );
};
export default Register;
