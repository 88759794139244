import React, { useState, useEffect } from "react";
import axios from "axios";

const TehsileMaster = () => {
  const [tehsileName, setTehsileName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch countries on page load (Reuse existing API)
  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/getcountries"
        );
        setCountries(response.data); // Reusing the /api/getcountries API
      } catch (err) {
        console.error("Error fetching countries:", err);
        setError("Failed to fetch countries");
      }
    }
    fetchCountries();
  }, []);

  // Fetch states based on selected country (Reuse existing API)
  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/getstates/${countryId}`
      );
      setStates(response.data); // Reusing the /api/getstates API
    } catch (err) {
      console.error("Error fetching states:", err);
      setError("Failed to fetch states");
    }
  };

  // Fetch cities based on selected state (New API)
  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/getcities/${stateId}`
      );
      setCities(response.data); // Fetch cities with a new API
    } catch (err) {
      console.error("Error fetching cities:", err);
      setError("Failed to fetch cities");
    }
  };

  // Handle country change and fetch states
  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    setCountryId(selectedCountryId);
    fetchStates(selectedCountryId); // Fetch states when country is selected
  };

  // Handle state change and fetch cities
  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    setStateId(selectedStateId);
    fetchCities(selectedStateId); // Fetch cities when state is selected
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/api/addtehsile",
        {
          CM_TehsilName: tehsileName,
          CM_CityId: cityId,
          IsActive: isActive,
        }
      );
      setMessage(response.data.message);
      setTehsileName("");
      setCountryId("");
      setStateId("");
      setCityId("");
      setStates([]); // Clear states
      setCities([]); // Clear cities
    } catch (err) {
      console.error("Error adding tehsile:", err);
      setError("Failed to add tehsile. Please try again.");
    }
  };

  return (
    <div>
      <h2>Add Tehsile</h2>
      <form onSubmit={handleSubmit}>
        {/* Tehsile Name */}
        <input
          type="text"
          value={tehsileName}
          onChange={(e) => setTehsileName(e.target.value)}
          placeholder="Tehsile Name"
          required
        />

        {/* Country Dropdown */}
        <select value={countryId} onChange={handleCountryChange} required>
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country.CM_CountryID} value={country.CM_CountryID}>
              {country.CM_CountryName}
            </option>
          ))}
        </select>

        {/* State Dropdown */}
        <select value={stateId} onChange={handleStateChange} required>
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.SM_StateID} value={state.SM_StateID}>
              {state.SM_StateName}
            </option>
          ))}
        </select>

        {/* City Dropdown */}
        <select
          value={cityId}
          onChange={(e) => setCityId(e.target.value)}
          required
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.CM_CityID} value={city.CM_CityID}>
              {city.CM_CityName}
            </option>
          ))}
        </select>

        {/* Is Active */}
        <label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          Is Active
        </label>

        <button type="submit">Add Tehsile</button>
      </form>

      {message && <p>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default TehsileMaster;
