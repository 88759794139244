import React from "react";
import { Slide } from "react-slideshow-image";
import "../assets/css/Slideshow.css";

const Images = [
  //   {
  //     url: "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  //     caption: "First Slide",
  //   },
  //   {
  //     url: "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
  //     caption: "Second Slide",
  //   },
  //   {
  //     url: "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
  //     caption: "Third Slide",
  //   },
  {
    url: "https://picsum.photos/1900/421",
    caption: "First Slide",
  },
  {
    url: "https://picsum.photos/1900/422",
    caption: "Second Slide",
  },
  {
    url: "https://picsum.photos/1900/420",
    caption: "Third Slide",
  },
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide>
        {Images.map((fadeImages, index) => (
          <div key={index}>
            <img src={fadeImages.url} alt={fadeImages.caption} />
            <h2>{fadeImages.caption}</h2>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
