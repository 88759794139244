import React from "react";
import AuthFormLayout from "./AuthFormLauout";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <AuthFormLayout>
      <h2>Login</h2>
      <form>
        <div className="auth-form-group">
          <label>Email</label>
          <input type="email" placeholder="Enter your email" required />
        </div>
        <div className="auth-form-group">
          <label>Password</label>
          <input type="password" placeholder="Enter your password" required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an account? <Link to="/register">Register here</Link>
      </p>
    </AuthFormLayout>
  );
};

export default Login;
