import React, { useState } from "react";
import "../assets/css/MiniHeader.css";
import "../assets/css/Filter.css";
import { FaFilter, FaTimes } from "react-icons/fa"; // FontAwesome for icons

function Filters({ onFilterChange }) {
  const [filters, setFilters] = useState({
    location: "",
    minPrice: "",
    maxPrice: "",
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false); // State to toggle filter form visibility

  const toggleFilterForm = () => {
    setIsFilterOpen(!isFilterOpen);
  };





  // This function handles the change events for all the input fields

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
      // Dynamically update the field that triggered the change
    });
  };

  // This function submits the filter form, passing the filter state to the parent component
  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the filters back to the parent component
    onFilterChange(filters);
  };

  return (

    <div className="filter-container">
    <button className="filter-icon" onClick={toggleFilterForm}>
      {isFilterOpen ? <FaTimes /> : <FaFilter />}
    </button>

    {isFilterOpen && (


    <div className="filters">
      {/* <h3>Filter Properties</h3> */}
      <form onSubmit={handleSubmit} >
        <div className="filter-group">
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={filters.location}
            onChange={handleInputChange}
            placeholder="Enter location"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="minPrice">Min Price:</label>
          <input
            type="number"
            id="minPrice"
            name="minPrice"
            value={filters.minPrice}
            onChange={handleInputChange}
            placeholder="Enter minimum price"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="maxPrice">Max Price:</label>
          <input
            type="number"
            id="maxPrice"
            name="maxPrice"
            value={filters.maxPrice}
            onChange={handleInputChange}
            placeholder="Enter maximum price"
          />
        </div>
        <button type="submit"> Apply </button>
      </form>
    </div>
      )}
    </div>
  );
}

export default Filters;
