import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/PropertySlider.css";

const PropertySlider = () => {
  const [activeTab, setActiveTab] = useState("Buy");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const properties = {
    Buy: [
      {
        id: 1,
        image: "https://picsum.photos/1900/421",
        title: "Luxurious Villa in the Hills",
        description: "A beautiful villa with stunning views.",
      },
      {
        id: 2,
        image: "https://picsum.photos/1900/420",
        title: "Modern Apartment in the City",
        description: "A spacious apartment in the heart of the city.",
      },
    ],
    Rent: [
      {
        id: 3,
        image: "https://picsum.photos/1900/422",
        title: "Cozy Cottage by the Sea",
        description: "A cozy cottage with a seaside view.",
      },
      {
        id: 4,
        image: "https://picsum.photos/1900/419",
        title: "Penthouse Suite Downtown",
        description: "An exquisite penthouse in a prime location.",
      },
    ],
    Sell: [
      {
        id: 5,
        image: "https://picsum.photos/1900/418",
        title: "Suburban Family Home",
        description: "A perfect family home in the suburbs.",
      },
      {
        id: 6,
        image: "https://picsum.photos/1901/421",
        title: "Country Estate",
        description: "A large estate in the countryside.",
      },
    ],
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="property-slider-container">
      <div className="slider-buttons">
        <button
          className={activeTab === "Buy" ? "active" : ""}
          onClick={() => handleTabClick("Buy")}
        >
          Buy
        </button>
        <button
          className={activeTab === "Rent" ? "active" : ""}
          onClick={() => handleTabClick("Rent")}
        >
          Rent
        </button>
        <button
          className={activeTab === "Sell" ? "active" : ""}
          onClick={() => handleTabClick("Sell")}
        >
          Sell
        </button>
      </div>
      <div className="property-slider">
        <Slider {...settings}>
          {properties[activeTab].map((property) => (
            <div key={property.id} className="slider-item">
              <img src={property.image} alt={property.title} />
              <div className="slider-buttons">
                <button
                  className={activeTab === "Buy" ? "active" : ""}
                  onClick={() => handleTabClick("Buy")}
                >
                  Buy
                </button>
                <button
                  className={activeTab === "Rent" ? "active" : ""}
                  onClick={() => handleTabClick("Rent")}
                >
                  Rent
                </button>
                <button
                  className={activeTab === "Sell" ? "active" : ""}
                  onClick={() => handleTabClick("Sell")}
                >
                  Sell
                </button>
              </div>
              <div className="slider-content">
                <h2 className="animated-text">{property.title}</h2>
                <p>{property.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PropertySlider;
