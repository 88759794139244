import React from "react";
import "../assets/css/AuthFormLayout.css";

const AuthFormLayout = ({ children }) => {
  return (
    <div className="auth-container">
      {/* Left side - image */}
      <div className="auth-image-section">
        <img src="https://via.placeholder.com/400" alt="Placeholder" />
      </div>

      {/* Right side - form content passed via children */}
      <div className="auth-form-section">{children}</div>
    </div>
  );
};

export default AuthFormLayout;
