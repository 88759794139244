import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faHome, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCommenting } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import "../assets/css/property-card.css";

const CardFooters = () => {
  return (
    <div className="card-footer">
      <div className="spacebtw" style={{ display: "flex" }}>
        <Link to="/" className="social-Link">
          <FontAwesomeIcon icon={faPhone} size="lg" />
        </Link>
        <Link to="/" className="social-Link">
          <FontAwesomeIcon icon={faWhatsapp} size="lg" />{" "}
        </Link>
        <Link to="/" className="social-Link">
          <FontAwesomeIcon icon={faCommenting} size="lg" />{" "}
        </Link>
        <Link to="/" className="social-Link">
          <FontAwesomeIcon icon={faHome} size="lg" />{" "}
        </Link>
        <Link to="/" className="social-Link">
          <FontAwesomeIcon icon={faShareAlt} size="lg" />{" "}
        </Link>
      </div>
    </div>
  );
};

export default CardFooters;
