import React from "react";
import "../assets/css/Stats.css";
import img1 from "../assets/images/FeatureSection/feature1.jpeg";
import img2 from "../assets/images/FeatureSection/feature2.jpeg";
import img3 from "../assets/images/FeatureSection/feature3.jpeg";

const FeatureProp = () => {
  return (
    <div>
      <div class="px-2 rounded-xl">
        <div
          class="max-w-[1440px] mx-auto lg:p-20 pb-2 pt-2 px-2"
          id="featured"
        >
          <div class="lg:p-20 lg:rounded-3xl bg-white shadow-xl py-16">
            <h2 class="mb-3 font-semibold lg:text-[48px] text-3xl text-center lg:text-left">
              Featured Property
            </h2>
            <p class="text-center lg:text-left text-gray-400 sm:mb-12 mb-10">
              Let's find you a comfortable place
            </p>
            <div class="flex flex-col lg:flex-row justify-center items-center lg:items-start">
              <div class="flex flex-col items-center lg:items-start lg:mr-12">
                <div class="image-container">
                  <img src={img1} alt="rent" class="image rounded-xl" />
                </div>
                <div class="image-content">
                  <h3 class="text-center lg:text-left text-3xl font-semibold mb-2">
                    Rent
                  </h3>
                  <p class="text-center lg:text-left text-gray-400">
                    We’re creating a seamless online experience – from shopping
                    on the largest rental network, to applying, to paying rent.
                  </p>
                  <a href="/" class="btn btn-rent bg-blue-600">
                    Rent
                  </a>
                </div>
              </div>
              <div class="flex flex-col items-center lg:items-start lg:mr-12">
                <div class="image-container">
                  <img src={img2} alt="buy" class="image rounded-xl" />
                </div>
                <div class="image-content">
                  <h3 class="text-center lg:text-left text-3xl font-semibold mb-2">
                    Buy
                  </h3>
                  <p class="text-center lg:text-left text-gray-400">
                    Find your place with an immersive photo expirience and the
                    most listings, including things you won't find elsewhere
                  </p>
                  <a href="/AllListings" class="btn btn-buy bg-blue-600">
                    Buy
                  </a>
                </div>
              </div>
              <div class="flex flex-col items-center lg:items-start">
                <div class="image-container">
                  <img src={img3} alt="sell" class="image rounded-xl" />
                </div>
                <div class="image-content">
                  <h3 class="text-center lg:text-left text-3xl font-semibold mb-2">
                    Sell
                  </h3>
                  <p class="text-center lg:text-left text-gray-400">
                    No matter what path you take to sell your home, we can help
                    you navigate a successful sale and with the best practice
                    and safety in mind
                  </p>
                  <a href="profile" class="btn btn-sell bg-blue-600">
                    Sell
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureProp;
