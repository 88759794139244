import React, { useState, useEffect } from "react";
import axios from "axios";

const VillageMaster = () => {
  const [villageName, setVillageName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [tehsilId, setTehsilId] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [tehsils, setTehsils] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch countries on page load
  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/getcountries"
        );
        setCountries(response.data);
      } catch (err) {
        console.error("Error fetching countries:", err);
        setError("Failed to fetch countries");
      }
    }
    fetchCountries();
  }, []);

  // Fetch states based on selected country
  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/getstates/${countryId}`
      );
      setStates(response.data);
    } catch (err) {
      console.error("Error fetching states:", err);
      setError("Failed to fetch states");
    }
  };

  // Fetch cities based on selected state
  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/getcities/${stateId}`
      );
      setCities(response.data);
    } catch (err) {
      console.error("Error fetching cities:", err);
      setError("Failed to fetch cities");
    }
  };

  // Fetch tehsils based on selected city
  const fetchTehsils = async (cityId) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/api/gettehsils/${cityId}`
      );
      setTehsils(response.data);
    } catch (err) {
      console.error("Error fetching tehsils:", err);
      setError("Failed to fetch tehsils");
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/api/addvillage",
        {
          VM_VillageName: villageName,
          VM_TehsilId: tehsilId,
          IsActive: isActive,
        }
      );
      setMessage(response.data.message);
      setVillageName("");
      setCountryId("");
      setStateId("");
      setCityId("");
      setTehsilId("");
      setStates([]);
      setCities([]);
      setTehsils([]);
    } catch (err) {
      console.error("Error adding village:", err);
      setError("Failed to add village. Please try again.");
    }
  };

  return (
    <div>
      <h2>Add Village</h2>
      <form onSubmit={handleSubmit}>
        {/* Village Name */}
        <input
          type="text"
          value={villageName}
          onChange={(e) => setVillageName(e.target.value)}
          placeholder="Village Name"
          required
        />

        {/* Country Dropdown */}
        <select
          value={countryId}
          onChange={(e) => {
            setCountryId(e.target.value);
            fetchStates(e.target.value); // Fetch states when country is selected
          }}
          required
        >
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country.CM_CountryID} value={country.CM_CountryID}>
              {country.CM_CountryName}
            </option>
          ))}
        </select>

        {/* State Dropdown */}
        <select
          value={stateId}
          onChange={(e) => {
            setStateId(e.target.value);
            fetchCities(e.target.value); // Fetch cities when state is selected
          }}
          required
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option key={state.SM_StateID} value={state.SM_StateID}>
              {state.SM_StateName}
            </option>
          ))}
        </select>

        {/* City Dropdown */}
        <select
          value={cityId}
          onChange={(e) => {
            setCityId(e.target.value);
            fetchTehsils(e.target.value); // Fetch tehsils when city is selected
          }}
          required
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.CM_CityID} value={city.CM_CityID}>
              {city.CM_CityName}
            </option>
          ))}
        </select>

        {/* Tehsil Dropdown */}
        <select
          value={tehsilId}
          onChange={(e) => setTehsilId(e.target.value)}
          required
        >
          <option value="">Select Tehsil</option>
          {tehsils.map((tehsil) => (
            <option key={tehsil.CM_TehsilID} value={tehsil.CM_TehsilID}>
              {tehsil.CM_TehsilName}
            </option>
          ))}
        </select>

        {/* Is Active */}
        <label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
          Is Active
        </label>

        <button type="submit">Add Village</button>
      </form>

      {message && <p>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default VillageMaster;
