// src/pages/HomePage.js
import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import PropertyCard from "../components/PropertyCard";
import Slideshow from "../components/Slideshow";
import Cart from "../components/Cart";
import Filters from "../components/Filter";
import "../assets/css/MiniHeader.css";
import Stats from "../components/Stats";
import FeatureSection from "../components/FeatureProp";
import PropertySlider from "../components/PropertySlider";
import HomeSection from "../components/HomeSection";

const HomePage = () => {
  const [cartItems, setCartItems] = useState([]);
  const handleAddToCart = (property) => {
    setCartItems([...cartItems, property]);
  };
  const featuredProperties = [
    {
      id: 1,
      title: "Beautiful House",
      description: "4 beds, 3 baths ",
      image: "https://loremflickr.com/150/140/house",
    },
    {
      id: 2,
      title: "Modern Apartment",
      description: "2 beds, 2 baths",
      image: "https://loremflickr.com/151/140/house",
    },
    // {
    //   id: 3,
    //   title: " Modern Apartment",
    //   description: "2 beds, 2 baths",
    //   image: "https://loremflickr.com/150/141/house",
    // },
    // {
    //   id: 4,
    //   title: "Modern Apartment",
    //   description: "2 beds, 2 baths",
    //   image: "https://loremflickr.com/151/141/house",
    // },
  ];
  const handleRemoveFromCart = (index) => {
    setCartItems(cartItems.filter((_, i) => i !== index));
  };

  const handleSearch = (query) => {
    console.log("Search query:", query);
  };

  return (
    <div className="home-page">
      {" "}
      <div>
        <div>
          <div className="MiniHeader">
            <div className="MHfilter">
              <Filters></Filters>
            </div>
            {/* <div className="MHsearchbar">
              <SearchBar onSearch={handleSearch} />
            </div> */}
          </div>
        </div>
        <HomeSection></HomeSection>
        {/* <PropertySlider></PropertySlider>
        <Slideshow></Slideshow> */}

        <FeatureSection></FeatureSection>
        <h2>Featured Properties</h2>
        {/* <div className="property-list">
          {featuredProperties.map((property) => (
            <PropertyCard
              key={property.id}
              property={property}
              onAddToCart={() => handleAddToCart(property)}
            />
          ))}
        </div> */}
        <div className="property-list">
          <PropertyCard></PropertyCard>
        </div>
        <Stats></Stats>

        {/* <div className="cart-info">
          <h3>
            {" "}
            <a href="/cart">Cart: {cartItems.length} items </a>
          </h3>
          <Cart cartItems={cartItems} onRemoveFromCart={handleRemoveFromCart} />
        </div> */}
      </div>
      <></>
    </div>
  );
};

export default HomePage;
