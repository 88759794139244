// src/pages/AddPropertyPage.js
import React, { useState } from "react";
import axios from "axios";
import "../assets/css/AddPropertyPage.css";

const AddPropertyPage = () => {
  const [property, setProperty] = useState({
    PM_Title: "",
    PM_Description: "",
    PM_Price: "",
    PM_Location: "",
    PM_Images: "",
    PM_OwnerName: "",
    PM_ContactNumber: "",
    PM_Type: "",
    PM_Bedrooms: "",
    PM_Bathrooms: "",
    PM_Area: "",
  });

  const handleChange = (e) => {
    setProperty({ ...property, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5000/api/properties", property)
      .then((response) => {
        alert("Property added successfully!");
      })
      .catch((error) => {
        console.error("There was an error adding the property!", error);
      });
  };

  return (
    <>
      <div class="header">
        <img
          src="https://picsum.photos/1900/400"
          alt="Header "
          class="header-image"
        />
        <h1 class="title"> Add Property </h1>
      </div>
      <div className="add-property-page">
        <h2>Add New Property</h2>
        <form className="property-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title:</label>

            <input
              type="text"
              name="PM_Title"
              value={property.PM_Title}
              onChange={handleChange}
              required
              placeholder="Enter property title"
            />
          </div>

          <div className="form-group">
            <label>Description:</label>
            <textarea
              type="text"
              name="PM_Description"
              value={property.PM_Description}
              onChange={handleChange}
              required
              placeholder="Enter property description"
            />
          </div>

          <div className="form-group">
            <label>Price:</label>
            <input
              type="number"
              name="PM_Price"
              value={property.PM_Price}
              onChange={handleChange}
              required
              placeholder="Enter price"
            />
          </div>

          <div className="form-group">
            <label>Location:</label>
            <input
              type="text"
              name="PM_Location"
              value={property.PM_Location}
              onChange={handleChange}
              required
              placeholder="Enter location"
            />
          </div>

          <div className="form-group">
            <label for="PM_Images">Images:</label>
            <input
              type="text"
              name="PM_Images"
              value={property.PM_Images}
              onChange={handleChange}
              placeholder="Enter image URLs, comma-separated"
            />
          </div>

          <div className="form-group">
            <label>Type :</label>
            <input
              type="text"
              name="PM_Type"
              value={property.PM_Type}
              onChange={handleChange}
              placeholder="Enter Property Type"
            />
          </div>

          <div className="form-group">
            <label>Bedrooms :</label>
            <input
              type="number"
              name="PM_Bedrooms"
              value={property.PM_Bedrooms}
              onChange={handleChange}
              placeholder="Enter Bedrooms"
            />
          </div>

          <div className="form-group">
            <label>Bathrooms :</label>
            <input
              type="number"
              name="PM_Bathrooms"
              value={property.PM_Bathrooms}
              onChange={handleChange}
              placeholder="Enter Bathrooms"
            />
          </div>

          <div className="form-group">
            <label>Area :</label>
            <input
              type="number"
              name="PM_Area"
              value={property.PM_Area}
              onChange={handleChange}
              placeholder="Enter Area"
            />
          </div>

          <div className="form-group">
            <label>Owner Name:</label>
            <input
              type="text"
              name="PM_OwnerName"
              value={property.ownerName}
              onChange={handleChange}
              required
              placeholder="Enter owner name"
            />
          </div>

          <div className="form-group">
            <label>Contact Number:</label>
            <input
              type="number"
              name="PM_ContactNumber"
              value={property.contactNumber}
              onChange={handleChange}
              required
              placeholder="Enter contact number"
            />
          </div>

          <button type="submit" className="submit-btn">
            Add Property
          </button>
        </form>
      </div>
    </>
  );
};

export default AddPropertyPage;
