import "../assets/css/SingleProperty.css";
import CardFooters from "./CardFooters";

import React, { useState } from "react";
import { useParams } from "react-router-dom";

const SingleProperty = () => {
  const { id } = useParams();

  // In a real application, you would fetch property details using the id
  const property = {
    id,
    title: "Luxury Villa",
    description:
      "This luxury villa offers 5 beds, 4 baths, a spacious living room, and a modern kitchen. Located in an exclusive neighborhood, this property is perfect for a family.",
    price: "$1,200,000",
    location: "456 Beverly Hills, CA",
    images: [
      "https://loremflickr.com/150/150/house1",
      "https://loremflickr.com/150/150/house2",
      "https://loremflickr.com/150/150/house3",
      "https://loremflickr.com/150/150/house4",
      "https://loremflickr.com/150/150/house5",
      "https://loremflickr.com/150/150/house1",
      "https://loremflickr.com/150/150/house2",
      "https://loremflickr.com/150/150/house3",
      "https://loremflickr.com/150/150/house4",
      "https://loremflickr.com/150/150/house5",
    ],
  };

  const [mainImage, setMainImage] = useState(property.images[0]);

  const handleMouseEnter = (image) => {
    setMainImage(image);
  };

  return (
    <div className="container">
      {/* Left Side Content */}
      <div className="main-content">
        <section className="property-info-singleproperty">
          <h2>Defense Colony, Ready to Move</h2>
          <p>10.12 Cr | 325 sq/yd</p>
          <p>D Block, Defense Colony</p>
          <div className="icons">
            <CardFooters></CardFooters>
          </div>
        </section>

        <section className="gallery">
          <div className="main-image-container">
            <img
              src={mainImage}
              alt="Main property"
              className="main-property-img"
            />
          </div>
          <div className="property-details-container">
            <div className="property-images">
              {property.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${property.title} ${index + 1}`}
                  className="thumbnail-img"
                  onMouseEnter={() => handleMouseEnter(image)}
                />
              ))}
            </div>
          </div>

          {/* <img
            src="https://loremflickr.com/150/140/house"
            alt="Property"
            className="gallery-image"
          /> */}
        </section>
        {/* <div className="property-info">
          <h1>{property.title}</h1>
          <p>
            <strong>Price:</strong> {property.price}
          </p>
          <p>
            <strong>Location:</strong> {property.location}
          </p>
          <p>{property.description}</p>
        </div> */}

        <section className="description">
          <h1>Description</h1>
          <p>New, Park Facing</p>
        </section>

        <section className="property-details">
          <h3>Property Details</h3>
          <div className="details-grid">
            <div>Property ID: RW170403</div>
            <div>Property Status: Ready to Move</div>
            <div>Available Floors: 1 of 4</div>
            <div>Listing Date: 03 Sept 2024</div>
            <div>Property Type: Builder Floor</div>
            <div>Bedrooms: 4</div>
            <div>Bathrooms: 2</div>
            <div>Owner Type: Freehold</div>
          </div>
        </section>

        <section className="floor-image">
          <h3>Floor Image</h3>
          <img src="floor-image.jpg" alt="Floor" className="floor-image" />
        </section>
      </div>

      {/* Right Side Content (Sidebar) */}
      {/* <div className="sidebar">
        <section className="owner-info">
          <h3>Owner Information</h3>
          <p>Owner Name: Mani Ashida Builder</p>
          <p>Available for Premium Users</p>
          <form className="inquiry-form">
            <input type="text" placeholder="Enter Name" required />
            <input type="email" placeholder="Enter Email" required />
            <input type="text" placeholder="Enter Mobile" required />
            <textarea placeholder="Enter Message"></textarea>
            <button type="submit">Submit</button>
          </form>
        </section>

        <section className="ad-section">
          <img src="ad-image.jpg" alt="Advertisement" />
        </section>
      </div> */}
    </div>
  );
};
export default SingleProperty;
