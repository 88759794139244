// src/pages/PropertyDetailsPage.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import "../assets/css/property-card.css";

function PropertyDetailsPage() {
  const { id } = useParams();

  // In a real application, you would fetch property details using the id
  const property = {
    id,
    title: "Luxury Villa",
    description:
      "This luxury villa offers 5 beds, 4 baths, a spacious living room, and a modern kitchen. Located in an exclusive neighborhood, this property is perfect for a family.",
    price: "$1,200,000",
    location: "456 Beverly Hills, CA",
    images: [
      "https://loremflickr.com/150/150/house1",
      "https://loremflickr.com/150/150/house2",
      "https://loremflickr.com/150/150/house3",
      "https://loremflickr.com/150/150/house4",
      "https://loremflickr.com/150/150/house5",
    ],
  };

  const [mainImage, setMainImage] = useState(property.images[0]);

  const handleMouseEnter = (image) => {
    setMainImage(image);
  };

  return (
    <div className="property-details-container">
      <div className="property-images">
        {property.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`${property.title} ${index + 1}`}
            className="thumbnail-img"
            onMouseEnter={() => handleMouseEnter(image)}
          />
        ))}
      </div>
      <div className="main-image-container">
        <img
          src={mainImage}
          alt="Main property"
          className="main-property-img"
        />
        <div className="property-info">
          <h1>{property.title}</h1>
          <p>
            <strong>Price:</strong> {property.price}
          </p>
          <p>
            <strong>Location:</strong> {property.location}
          </p>
          <p>{property.description}</p>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsPage;
